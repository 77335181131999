import Vue from 'vue'
import VueRouter from 'vue-router'
// import Feed from '@/views/Feed.vue'
// import Search from '@/views/Search.vue'
// import Link from '@/views/Link.vue'
// import Redirect from '@/views/Redirect.vue'
// import ArticleView from '@/views/ArticleView.vue'
// import About from '@/views/About.vue'
// import Confidentialite from '@/views/Confidentialite.vue'
// import Settings from '@/views/Settings.vue'
import { store } from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/search/:search?',
    name: 'Search',
    component: () => import(
      /* webpackChunkName: "Search" */ '../views/Search.vue'),
    // component: Search,
    props: true
  },
  {
    path: '/dossier/:search?',
    name: 'Dossier',
    component: () => import(
      /* webpackChunkName: "Search" */ '../views/Search.vue'),
    // component: Search,
    props: true
  },
  {
    path: '/',
    name: 'Feed',
    component: () => import(
      /* webpackChunkName: "Feed" */
      /* webpackPreload: true */
      '../views/Feed.vue')
    // component: Feed
  },
  {
    path: '/dossiers',
    name: 'Files',
    component: () => import(
      /* webpackChunkName: "Files" */
      /* webpackPreload: true */
      '../views/Files.vue')
    // component: Files
  },
  {
    path: '/categories/:category',
    name: 'Category',
    component: () => import(/* webpackChunkName: "Feed" */ '../views/Feed.vue'),
    // component: Feed,
    props: true
  },
  {
    path: '/redirect/:id',
    name: 'Redirect',
    component: () => import(/* webpackChunkName: "Redirect" */ '../views/Redirect.vue'),
    // component: Redirect,
    props: true
  },
  {
    path: '/link/:url+',
    name: 'Link',
    component: () => import(/* webpackChunkName: "Link" */ '../views/Link.vue'),
    // component: Link,
    props: true
  },
  {
    path: '/article/:id/:slug+',
    name: 'ArticleView',
    component: () => import(
      /* webpackChunkName: "ArticleView" */
      /* webpackPreload: true */
      '../views/ArticleView.vue'),
    // component: ArticleView,
    props: true
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: () => import(
      /* webpackChunkName: "Statistics" */
      /* webpackPreload: true */
      '../views/Statistics.vue'),
    // component: ArticleView,
    props: true
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "About" */ '../views/About.vue')
    // component: About
  },
  {
    path: '/live',
    name: 'Live',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Live" */ '../views/Live.vue')
    // component: Live
  },
  {
    path: '/confidentialite',
    name: 'Confidentialite',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Confidentialite" */ '../views/Confidentialite.vue')
    // component: Confidentialite
  },
  {
    path: '/personnalisation',
    name: 'Settings',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Settings" */ '../views/Settings.vue')
    // component: Settings
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
  // scrollBehavior (to, from, savedPosition) {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       if (savedPosition) {
  //         console.log('returning saved position')
  //         resolve(savedPosition)
  //       } else {
  //         resolve({ x: 0, y: 0 })
  //       }
  //     }, 500)
  //   })
  // }
})

router.afterEach((to, from) => {
  if (from.name === 'Feed') {
    store.position = window.scrollY
  }
  if (to.name !== 'Feed') {
    window.scrollTo(0, 0)
  } else {
    setTimeout(() => { window.scrollTo(0, store.position) }, 100)
  }
})

router.beforeEach((to, from, next) => {
  // console.log(to, from)
  next()
})

export default router
