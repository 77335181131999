<template>
  <div class="sticky footer onFront full-width" :class="[store.getTheme()]">
    <router-link :class="[store.getTheme()]" class="waves-effect btn-flat" :to="{ name: 'About' }">A propos</router-link>
    <router-link :class="[store.getTheme()]" class="waves-effect btn-flat" :to="{ name: 'Confidentialite' }">Confidentialité</router-link>
    <a href="#tarteaucitron" :class="[store.getTheme()]" class="waves-effect btn-flat">Cookies</a>
  </div>
</template>

<script>
import { store } from '@/store'
export default {
  data () {
    return {
      store: store
    }
  }
}
</script>
