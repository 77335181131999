import axios from 'axios'
// import FileDownload from 'js-file-download'
// import EventBus from './EventBus.js'
// const baseUrl = 'http://localhost:5001/mahana-tahiti/us-central1/main/'
const baseUrl = window.location.host === 'localhost:8080' ? 'http://localhost:5001/mahana-tahiti/us-central1/main/' : 'https://' + window.location.host + '/api/'
// const baseUrl = 'https://mahanatahiti.pf/api/'
// const isHandlerEnabled = (config={}) => {
//   return config.hasOwnProperty('handlerEnabled') && !config.handlerEnabled ?
//     false : true
// }
// var retry = 10
// axios.defaults.withCredentials = true
// const api = axios.create({
// withCredentials: true
// });
// const errorHandler = (error) => {
//   if (isHandlerEnabled(error.config)) {
//     this.getNewToken()
//   }
//   return Promise.reject({ ...error })
// }
export default {
  // downloadFile: function (ref, url, token) {
  //   axios({
  //     method: 'get',
  //     // responseType: 'json',
  //     url: url,
  //     headers: {
  //       Authorization: 'Bearer ' + token,
  //       // Accept: 'application/json',
  //       // 'Content-Type': 'application/json'
  //     }
  //   }).then(response => {
  //     saveDataToFile(reponse.data,ref,response.headers.content-type)
  //   })
  // },
  mesure (type, category, label, id, userToken) {
    const parameters = []
    if (category) {
      parameters.push('category=' + category)
    }
    if (label) {
      parameters.push('label=' + label)
    }
    if (id) {
      parameters.push('id=' + id)
    }
    if (userToken) {
      parameters.push('userToken=' + userToken)
    }

    this.post(`traces/${type}?` + parameters.join('&'))
    // eslint-disable-next-line no-undef
    if (gtag) {
      // eslint-disable-next-line no-undef
      gtag('event', type, {
        event_category: category || '',
        event_label: label || ''
      })
    }
  },

  getImagesUrl () {
    // return baseUrl + 'images'
    return 'https://mahanatahiti.pf/api/images'
  },
  getHostUrl () {
    return 'https://mahanatahiti.pf' // + window.location.host
  },
  stringToSlug (str) {
    str = str.replace(/^\s+|\s+$/g, '') // trim
    str = str.toLowerCase()
    // remove accents, swap ñ for n, etc
    var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
    var to = 'aaaaeeeeiiiioooouuuunc------'
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
    }
    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-') // collapse dashes
    return str
  },

  // Twice faster than Object.values
  extractValues: function (obj) {
    const t = []
    for (var key in obj) {
      t.push(obj[key])
    }
    return t
  },
  signalerErreur: function (message) {
    // eslint-disable-next-line no-undef
    M.Toast.dismissAll()
    // eslint-disable-next-line no-undef
    M.toast({ html: message, classes: 'red darken-2' })
  },
  generateUUID () {
    var d = new Date().getTime()
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0
      d = Math.floor(d / 16)
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
    })
  },
  // signaler: function (message) {
  //   M.toast({html: message, classes: 'green darken-2'})
  // },
  // storeUser: function (user) {
  //   // localStorage.setItem('token', user.stsTokenManager.accessToken)
  //   // localStorage.setItem('refreshToken', user.stsTokenManager.refreshToken)
  //   localStorage.setItem('email', user.email)
  //   localStorage.setItem('uid', user.uid)
  //   localStorage.setItem('groups', JSON.stringify(user.groups))
  // },
  // getUser: function () {
  //   if (localStorage.getItem('email') && localStorage.getItem('uid')) {
  //     return {email: localStorage.getItem('email'), uid: localStorage.getItem('uid')}
  //   } else {
  //     return null
  //   }
  // },
  // removeUser: function () {
  //   // localStorage.removeItem('user')
  //   // localStorage.removeItem('token')
  //   localStorage.removeItem('email')
  //   localStorage.removeItem('uid')
  //   localStorage.removeItem('groups')
  //   // localStorage.removeItem('refreshToken')
  // },
  // isConnected: function () {
  //   console.log(localStorage.getItem('email'))
  //   return localStorage.getItem('email')
  // },
  // getGroups: function () {
  //   const groups = JSON.parse(localStorage.getItem('groups'))
  //   if (groups) {
  //     return groups
  //   } else {
  //     return {}
  //   }
  // },

  // getTokenFromStorage: function () {
  //   // console.log(localStorage.getItem('token'))
  //   // axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')
  //   // return localStorage.getItem('token')
  //   axios.defaults.withCredentials = true
  // },
  refToUrl: function (ref) {
    return baseUrl + ref
  },
  get: function (ref) {
    return axios({
      method: 'get',
      responseType: 'json',
      url: this.refToUrl(ref),
      headers: {
        // Authorization: 'Bearer ' + token,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
  },
  // delete: function (ref) {
  //   return axios({
  //     method: 'delete',
  //     responseType: 'json',
  //     url: this.refToUrl(ref),
  //     headers: {
  //       // Authorization: 'Bearer ' + token,
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json'
  //     }
  //   })
  // },
  // patch: function (ref, data) {
  //   return axios({
  //     method: 'patch',
  //     responseType: 'json',
  //     url: this.refToUrl(ref),
  //     headers: {
  //       // Authorization: 'Bearer ' + token,
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json'
  //     },
  //     data: data
  //   })
  // },
  post: function (ref, data, contentType) {
    let finalContentType = 'application/json'
    if (contentType) {
      finalContentType = contentType
    }
    return axios({
      method: 'post',
      responseType: 'json',
      url: this.refToUrl(ref),
      headers: {
        // Authorization: 'Bearer ' + token,
        Accept: 'application/json',
        'Content-Type': finalContentType
      },
      data: data
    })
  },
  // upload: function (ref, data) {
  //   return axios({
  //     method: 'post',
  //     responseType: 'json',
  //     url: this.refToUrl(ref),
  //     headers: {
  //       // Authorization: 'Bearer ' + token,
  //       Accept: 'application/json',
  //       'Content-Type': 'multipart/form-data'
  //     },
  //     data: data
  //   })
  // },
  // download: function (ref) {
  //   return axios({
  //     url: this.refToUrl(ref),
  //     method: 'get',
  //     responseType: 'blob', // important
  //     headers: {
  //     }
  //   })
  // },
  // openDownloadWindow(response) {
  //   // console.log (response)
  //   const contentDisposition = response.headers['content-disposition']
  //   // console.log(contentDisposition)
  //   let filename = 'unknown';
  //   if (contentDisposition) {
  //       const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
  //       if (fileNameMatch.length === 2)
  //           filename = fileNameMatch[1];
  //   }
  //   FileDownload(response.data, filename)
  // },

  put: function (ref, data) {
    return axios({
      method: 'put',
      responseType: 'json',
      url: this.refToUrl(ref),
      headers: {
        // Authorization: 'Bearer ' + token,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: data
    })
  }
  // findById: function (tableau, id) {
  //   return tableau.find(object => { return object.id === id })
  // },
  // onlyUnique: function (value, index, self) {
  //   return self.indexOf(value) === index
  // }
  // saveDataToFile(data, fileName, mimeType) {
  //   const blob = new Blob([data], { type: mimeType })
  //   const url = window.URL.createObjectURL(blob)
  //   this.downloadURL(url, fileName, mimeType)
  //   setTimeout(() => {
  //       window.URL.revokeObjectURL(url)
  //   }, 1000);
  // },
  // saveDataToFile(data, fileName) {
  //   const blob = new Blob([data])
  //   const url = window.URL.createObjectURL(blob)
  //   this.downloadURL(url, fileName)
  //   setTimeout(() => {
  //       window.URL.revokeObjectURL(url)
  //   }, 1000);
  // },
  // downloadURL(data, fileName) {
  //     const a = document.createElement('a');
  //     a.href = data;
  //     a.download = fileName;
  //     document.body.appendChild(a);
  //     a.style = 'display: none';
  //     a.click();
  //     a.remove();
  // },
}

// axios.interceptors.response.use(function (response) {
//   // Any status code that lie within the range of 2xx cause this function to trigger
//   // Do something with response data
//   return response
// }, function (error) {
//   if (error.config && error.response && error.response.status === 401) {
//     console.log('error handler 401')
//     EventBus.$emit('loggedOut', 'User logged out')
//     // on a perdu l'identification il faut se relogguer

//     // return axios({
//     //   method: 'post',
//     //   responseType: 'json',
//     //   url: baseUrl + '/refresh',
//     //   headers: {
//     //     // Authorization: 'Bearer ' + token,
//     //     Accept: 'application/json',
//     //   },
//     //   data: {token: localStorage.getItem('refreshToken')}
//     // }).then(response => {
//     //   const token = response.data
//     //   axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
//     //   error.config.headers.Authorization = 'Bearer ' + token
//     //   localStorage.setItem('token', token)
//     //   retry--
//     //   console.log(retry)
//     //   if (retry >= 0) {
//     //     console.log("retrying")
//     //     return axios.request(error.config)
//     //   }
//     //   else {
//     //     retry = 10
//     //     console.log("not retrying anymore")
//     //     M.toast({html: "Problème réseau", classes: 'red darken-2'})
//     //     return Promise.reject({ error })
//     //   }
//     // }).catch(err => {
//     //   console.log(err)
//     //   // this.signalerErreur('Problème d\'authentification')
//     // })
//   }if (error.config && error.response && error.response.status === 403) {
//     M.toast({html: "Droits insuffisants pour accéder à cette ressource", classes: 'red darken-2'})
//   } else {
//     M.toast({html: "Problème réseau", classes: 'red darken-2'})

//   }
//   // Any status codes that falls outside the range of 2xx cause this function to trigger
//   // Do something with response error
//   return Promise.reject(error)
// })
