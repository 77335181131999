<template>
   <div>
    <nav ref="navbar" class="sticky top onFront" :class="[store.getTheme()]">
      <div class="nav-wrapper">
          <router-link class="btn-flat waves-effect left valign-wrapper no-padding" :class="[store.getTheme()]" :to="{ name: 'Feed' }">
            <img class="hide-on-small-only"  src="/img/MahanaTahitiNEWS.svg" height="60px" width="106px" alt="Mahana Tahiti News">
            <img class="hide-on-med-and-up"  src="/img/MahanaTahitiNewsSmall.svg" height="50px" width="50px" alt="Mahana Tahiti News">
          </router-link>
          <div class="hide-on-small-only left"><h1>Mahana Tahiti, 100% de l'actualité de Polynésie !</h1></div>
          <a :class="[store.getTheme(), {selected: $route.name === 'Search'}]" class="btn-flat waves-effect right" @click="toggleSearch">
            <font-awesome-icon class="valign" :icon="['fas', 'search']" size="lg" />
          </a>
          <router-link class="btn-flat waves-effect right" :class="[store.getTheme(), {selected: $route.name === 'Settings'}]" :to="{ name: $route.name === 'Settings' ? 'Feed' : 'Settings' }">
            <font-awesome-icon class="valign" :icon="['fas', 'cog']" size="lg" />
          </router-link>
          <router-link class="btn-flat waves-effect right" :class="[store.getTheme(), {selected: $route.name === 'Files'}]" :to="{ name: 'Files' }">
            <font-awesome-icon class="valign" :icon="['fas', 'folder-open']" size="lg" />
          </router-link>
          <!-- <a :class="[store.getTheme(), {disabled: store.loading}]" class="btn-flat waves-effect right hide-on-small-only" @click="refresh">
            <font-awesome-icon class="valign" :icon="['fas', 'redo']" size="lg" />
          </a> -->
      </div>
      <transition name="slide-fade">
        <div ref="searchBar" v-show="searchbarDisplayed" class="nav-content" :class="[store.getTheme()]">
          <div class="row">
            <div class="col s2 l3" :class="[store.getTheme()]">
              <font-awesome-icon class="top-margin right" :icon="['fas', 'search']" size="lg" />
            </div>
            <div class="input-form col s8 l6">
              <input ref="search" type="search" class="background" :class="[store.getTheme()]" v-model="search" placeholder="Recherche" >
            </div>
            <div class="col s2 l3">
              <router-link class="left btn-flat waves-effect top-margin-cross" :class="[store.getTheme()]" :to="{ name: 'Feed' }">
                <font-awesome-icon  class="" :icon="['fas', 'times']" size="lg" />
              </router-link>
            </div>
          </div>
          <div  :class="[store.getTheme()]">
            <a v-for="(category, i) in store.categories" :key="i" class="waves-effect categories-btn" :class="[store.getTheme(), {active: categories[category] === true}]" @click="toggleCategory(category)">
              {{category}}
            </a>
          </div>
        </div>
        <!-- <div ref="fileBar" v-show="filesDisplayed" class="nav-content" :class="[store.getTheme()]">
          <div class="row">
          <div  :class="[store.getTheme()]">
            <a v-for="(filesType, i) in store.filesType" :key="i" class="waves-effect categories-btn" :class="[store.getTheme(), {active: filesTypes[filesType] === true}]" @click="toggleFilesType(filesType)">
              {{filesType}}
            </a>
          </div>
        </div> -->
      </transition>
    </nav>
  </div>
</template>

<script>
import { store } from '@/store'
import debounce from 'lodash/debounce'
import shared from '../shared'
export default {
  name: 'Navbar',
  data () {
    return {
      search: '',
      filesTypes: {},
      collapsible: null,
      installable: null,
      searchbarDisplayed: this.$route.name === 'Search',
      store: store,
      debouncedHandleResize: debounce(this.handleResize, 500)
    }
  },
  methods: {
    // refresh () {
    //   this.$bus.$emit('refresh', 'Refresh requested')
    // },
    initSearch () {
      this.search = this.$route.params.search || ''
    },
    initCategories () {
      if (this.$route.query.categories) {
        this.$route.query.categories.split(',').forEach(category => {
          this.categories[category] = true
        })
      } else {
        this.categories = {}
      }
    },
    toggleCategory (category) {
      this.$set(this.categories, category, !this.categories[category])
      if (!this.categories[category]) {
        this.$delete(this.categories, category)
      }
    },
    toggleSearch () {
      this.searchbarDisplayed = !this.searchbarDisplayed
      if (this.searchbarDisplayed) {
        setTimeout(function () {
          this.$refs.search.focus()
        }.bind(this), 100)
      }
      this.notifyNavbarChanged(200)
      this.searchTimer = setTimeout(async function () {
        this.goToRoute(this.$route.name === 'Search' ? 'Feed' : 'Search')
      }.bind(this), 200)
    },
    async initFeeds () {
      if (Object.keys(store.feeds).length === 0) {
        // Loading feeds
        const responseFeed = await shared.get('feeds')
        responseFeed.data.forEach(feed => {
          this.$set(store.feedsDisplayed, feed.id, JSON.parse(localStorage.getItem(feed.id)))
          if (store.feedsDisplayed[feed.id] === null) {
            this.$set(store.feedsDisplayed, feed.id, feed.display)
          }
          if (store.feeds[feed.category]) {
            if (!store.feeds[feed.category].find(otherFeed => { return otherFeed.id === feed.id })) {
              store.feeds[feed.category].push(feed)
            }
          } else {
            store.feeds[feed.category] = [feed]
            store.categories.push(feed.category)
          }
        })
      }
    },
    goToRoute (name) {
      const route = { name: name }
      if (name === 'Search' && this.search) {
        route.params = { search: this.search }
      }
      if (name === 'Search' && this.categoriesToUrl) {
        route.query = { categories: this.categoriesToUrl }
      }
      if (this.categoriesToUrl !== (this.$route.query.categories ? this.$route.query.categories : '') || route.name !== this.$route.name || this.search !== (this.$route.params.search ? this.$route.params.search : '')) {
        this.$router.push(route)
      }
    },
    handleResize () {
      this.notifyNavbarChanged(400)
    },
    notifyNavbarChanged (timeout) {
      setTimeout(function () {
        store.navbarOffset = [this.$refs.navbar.offsetHeight, 0, this.$refs.searchBar.offsetHeight]
        this.$bus.$emit('navbarResize', store.navbarOffset)
      }.bind(this), timeout || 400)
    }
  },
  computed: {
    categoriesToUrl () {
      return Object.keys(this.categories).join(',')
    }
  },
  watch: {
    searchbarDisplayed () {
      if (this.searchbarDisplayed) {
        this.initFeeds()
      }
    },
    '$route.params.search' () {
      this.initSearch()
    },
    '$route.query.categories' () {
      this.initCategories()
    },
    '$route.name' () {
      this.searchbarDisplayed = this.$route.name === 'Search'
      if (this.$route.name === 'Dossier') {
        this.initFeeds()
      }
      this.notifyNavbarChanged(400)
    },
    async search () {
      if ((this.search && this.$route.name !== 'Dossier') || this.$route.name === 'Search') {
        clearTimeout(this.searchTimer)
        this.searchTimer = setTimeout(async function () {
          this.goToRoute('Search')
        }.bind(this), 500)
      }
    },
    categories: {
      deep: true,
      async handler () {
        if (this.categoriesToUrl || this.$route.name === 'Search') {
          this.searchTimer = setTimeout(async function () {
            this.goToRoute('Search')
          }.bind(this), 500)
        }
      }
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.debouncedHandleResize, { passive: true })
  },
  mounted () {
    this.notifyNavbarChanged(400)
    window.addEventListener('resize', this.debouncedHandleResize, { passive: true })
  },
  async created () {
    this.initSearch()
    this.initCategories()
  }
}

</script>
<style scoped>
.no-padding {
  padding:0!important;
}
h1 {
  font-size: 15px!important;
  margin-top: 22px;
  margin-left: 10px;
}
.btn-flat {
  height:100%;
  margin:0!important;
}
.top-margin {
  margin-top:20px !important;
}
.top-margin-cross {
  margin-top:10px !important;
}
.slide-fade-enter-active {
  transition: all .1s ease;
}
.slide-fade-leave-active {
  transition: all .1s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-20px);
  opacity: 0;
}
.categories-btn {
  padding: 0px 8px 0px 8px !important;
  max-height: 40px;
  line-height: 40px;
}
</style>
