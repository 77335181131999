<template>
  <div id="app" class="background" :class="[store.getTheme()]" >
    <header>
      <p>Sur Mahana Tahiti, retrouvez toute les news polysiennes dès qu'elles sont publiees sur vos medias favoris</p>
      <script type="application/ld+json">
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "url": "https://mahanatahiti.pf/",
          "logo": "https://mahanatahiti.pf/img/MahanaTahitiNEWS.svg"
        }
      </script>
      <script type="application/ld+json">
        {
          "@context": "https://schema.org",
          "@type": "WebSite",
          "url": "https://mahanatahiti.pf/",
          "potentialAction": {
            "@type": "SearchAction",
            "target": "https://mahanatahiti.pf/search/{search_term_string}",
            "query-input": "required name=search_term_string"
          }
        }
      </script>
    </header>
    <Navbar ref="navbar"/>
    <router-view :style="`padding-top:${offset}px; transition: padding .3s ease;padding-bottom:40px`" />
    <Footer />
  </div>
</template>
<script>
import Footer from '@/components/Footer.vue'
import Navbar from '@/components/Navbar'
import { store } from '@/store'
import '@/assets/css/social.css'

export default {
  name: 'App',
  data () {
    return {
      store: store,
      offset: 0,
      description: 'Mahana Tahiti, c\'est votre portail pour ne rien rater de l\'actualité en Polynésie française. Retrouvez toute l\'information de Tahiti et ses îles.'
    }
  },
  metaInfo () {
    return {
    // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Mahana Tahiti, toute l\'actualité de Polynésie',
      // all titles will be injected into this template
      // titleTemplate: '%s partagé via Mahana Tahiti',
      meta: [
        { name: 'description', content: this.description, vmid: 'description' },
        { property: 'og:description', content: this.description, vmid: 'description' },
        { property: 'og:title', content: 'Mahana Tahiti, toute l\'actualité de Polynésie', vmid: 'description' },
        { property: 'og:image', content: this.image, vmid: 'description' },
        { property: 'og:type', content: 'article', vmid: 'description' },
        { name: 'twitter:card', content: 'summary_large_image', vmid: 'description' },
        { name: 'twitter:site', content: '@MahanaTahitiPf', vmid: 'description' },
        { name: 'twitter:title', content: 'Mahana Tahiti, toute l\'actualité de Polynésie', vmid: 'description' },
        { name: 'twitter:description', content: this.description, vmid: 'description' },
        { name: 'twitter:img:src', content: 'https://mahanatahiti.pf/img/MahanaTahitiNEWS.png', vmid: 'description' }
      ]

    }
  },
  // metaInfo () {
  //   return {
  //     title: 'Mahana Tahiti, toute l\'actualité de Polynésie',
  //     meta: [
  //       { name: 'description', content: 'Mahana Tahiti, c\'est votre portail pour ne rien rater de l\'actualité en Polynésie française. Retrouvez toute l\'information de Tahiti et ses îles en provenance de vos sites d\'information préférés.' },
  //       { property: 'og:description', content: 'Mahana Tahiti, c\'est votre portail pour ne rien rater de l\'actualité en Polynésie française. Retrouvez toute l\'information de Tahiti et ses îles en provenance de vos sites d\'information préférés.' },
  //       { property: 'og:title', content: 'Mahana Tahiti, toute l\'actualité de Polynésie' },
  //       { property: 'og:image', content: 'https://mahanatahiti.pf/img/icons/android-chrome-192x192.png' },
  //       { property: 'og:site_name', content: 'Mahana Tahiti' },
  //       { property: 'og:type', content: 'website' }
  //       // { name: 'robots', content: 'index,follow' }
  //     ]
  //   }
  // },
  components: {
    Navbar, Footer
  },
  async created () {
    if (this.$workbox) {
      // console.log('adding waiting listener in created')
      this.$workbox.addEventListener('waiting', async () => {
        await this.$workbox.messageSW({ type: 'SKIP_WAITING' })
        // console.log('waiting for new install')
        // this.prompt = true
      })
    }
    this.$bus.$on('navbarResize', (S) => {
      this.offset = S[1] + S[2] + 10
    })
  },
  methods: {
    async update () {
      await this.$workbox.messageSW({ type: 'SKIP_WAITING' })
    }
  },
  watch: {
    'store.theme' () {
      if (store.theme === 'dark-theme') {
        require('@/assets/css/dark-theme.css')
      } else {
        require('@/assets/css/light-theme.css')
      }
    }
  }
}
</script>
<style>

#app {
  font-family: 'Roboto Condensed', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

::-webkit-scrollbar {
    width: 8px;
    background-color: #dadada;
}
::-webkit-scrollbar-thumb {
    background-color: #aaaaaa;
}

html {
  background: #9e9e9e;
  overflow-y: scroll;
}
#app {
  min-height: 100vh;
}
.title {
  font-size: 24px;
  cursor: default
}
.valign {
  height: 100%;
}
.top {
  top: 0;
}
.sticky {
  position: fixed!important;
  /* width: 100%;
   */
}

.onFront {
  z-index:1000;
}

.footer {
  position: fixed!important;
  bottom: 0;
  height: 30px;
  /* width: 100%;
  z-index:1000; */
}
.small-padding-left {
  padding-left: 20px!important;
}
.full-width {
  width: 100%
}

/* class for image in cards */
.image-custom-class {
  object-fit: cover;
  -o-object-fit: cover;
  object-position:center;
  min-width:100%;
  min-height:290px;
  z-index:0;
}

</style>
