import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import './registerServiceWorker'
import wb from './registerServiceWorker'
import VueObserveVisibility from 'vue-observe-visibility'
import EventBus from './EventBus.js'
import VueMeta from 'vue-meta'
import VueScreen from 'vue-screen'
import VueProgressiveImage from 'vue-progressive-image'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebookF, faTwitter, faLinkedin, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import {
  faEnvelope, faMobileAlt, faSearchPlus, faShareAlt, faArrowsAlt, faChevronRight, faChevronDown, faChevronUp, faSearch, faChevronLeft, faDownload,
  faTimes, faEye, faEyeSlash, faClipboard, faCog, faFolderOpen
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faFacebookF, faTwitter, faLinkedin, faWhatsapp, faEnvelope, faMobileAlt, faSearchPlus, faShareAlt, faSearch, faClipboard, faCog,
  faArrowsAlt, faChevronRight, faChevronUp, faChevronDown, faChevronLeft, faDownload, faTimes, faEye, faEyeSlash, faFolderOpen)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.prototype.$bus = EventBus
Vue.prototype.$workbox = wb
require('materialize-css/dist/css/materialize.min.css')
require('materialize-css/dist/js/materialize.min.js')
require('@/assets/css/xxl.css')
Vue.use(VueMeta, {
  // optional pluginOptions
  // refreshOnceOnNavigation: true
})
Vue.use(VueScreen, 'materialize')
Vue.use(VueObserveVisibility)

Vue.use(VueProgressiveImage)
Vue.config.devtools = true
Vue.config.productionTip = false
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
