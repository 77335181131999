import shared from './shared'
export const store = {
  loading: false,
  feedsDisplayed: [],
  categories: [],
  feeds: {},
  apiFeeds: null,
  articles: {},
  similarArticles: [],
  olderArticleLoaded: null,
  mostRecentArticleLoaded: null,
  position: 0,
  navbarOffset: [54, 0, 0],
  days: {},
  setNotificationGranted (status) {
    if (window.Notification && status) {
      Notification.requestPermission(status => {
        if (status === 'granted') {
          this.notificationGranted = true
          localStorage.setItem('notificationGranted', true)
        } else {
          shared.signalerErreur('Impossible d\'activer les notifications. Merci de modifier le réglage de votre navigateur.')
          this.notificationGranted = false
          localStorage.setItem('notificationGranted', false)
        }
      })
    } else {
      this.notificationGranted = false
      localStorage.setItem('notificationGranted', false)
    }
  },
  async updateNotificationGranted () {
    this.notificationGranted = localStorage.getItem('notificationGranted')
    if (this.notificationGranted === null) {
      this.notificationGranted = false
    } else {
      this.notificationGranted = JSON.parse(this.notificationGranted)
      // We check if we have the authorization otherwize change the status
      if (window.Notification && this.notificationGranted) {
        const status = await Notification.requestPermission()
        if (status !== 'granted') {
          this.notificationGranted = false
        }
      }
    }
  },
  toggleNotificationGranted () {
    this.setNotificationGranted(!this.notificationGranted)
  },
  notificationGranted: false,
  display: null,
  changeDisplay () {
    if (this.getDisplay() === 'list') {
      this.display = 'grid'
    } else {
      this.display = 'list'
    }
    localStorage.setItem('display', this.display)
  },
  setDisplay (value) {
    if (value === 'grid' || value === 'list') {
      this.display = value
      localStorage.setItem('display', this.display)
    } else {
      console.log('bad value', value)
    }
  },
  getDisplay () {
    const display = localStorage.getItem('display')
    this.display = display || 'list'
    return this.display
  },
  theme: null,
  setTheme (theme) {
    if (theme) {
      localStorage.setItem('theme', theme)
      this.theme = theme
    } else {
      localStorage.removeItem('theme')
      this.theme = null
    }
  },
  getTheme () {
    if (this.theme) {
      return this.theme
    } else {
      const themeStorage = this.getPreferedTheme()
      this.theme = themeStorage || this.getAutoTheme()
      return this.theme
    }
  },
  getPreferedTheme () {
    return localStorage.getItem('theme')
  },
  getAutoTheme () {
    return ((window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) ? 'dark-theme' : 'light-theme')
  },
  // This function return or generate the user token
  getUserToken () {
    if (this.userToken) {
      return this.userToken
    } else {
      this.userToken = localStorage.getItem('userToken')
      if (!this.userToken) {
        this.userToken = shared.generateUUID()
        localStorage.setItem('userToken', this.userToken)
      }
      return this.userToken
    }
  },
  userToken: null
}
store.updateNotificationGranted()
